
html,
body {
  margin: 0;
  font-family: 'Lato', 'sans-serif';
  background-color: #FFFFFF;
}
.swiper-button-next {
    width: 10px;
    height: 10px;
    color: #20204B; 
    background-color: #F3F3FB;
    padding: 20px;
    border-radius: 50%;
  }

  .swiper-button-prev{
    width: 10px;
    height: 10px;
    color: #20204B; 
    background-color: #F3F3FB;
    padding: 20px;
    border-radius: 50%;
  }